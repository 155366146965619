<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{name: 'personal-dashboard'}">Dashboard</router-link></li>
        <li class="breadcrumb-item active">My Schedules</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">My Schedules</h2>
          </div>
          <div class="col text-end">
            <router-link :to="{name: 'add-schedule'}" v-if="$store.state.permittedActions.includes(444)" class="btn btn-outline-primary">Add</router-link>
          </div>
      </div>
        <div class="t-layout">
          <div class="row">
              <div class="col-md-12">
                <div v-if="this.$store.state.loader">
                    <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
                </div>
                <div v-else class="card">
                <div class="card-header">
                  <flash-message class="myCustomClass"></flash-message>
                </div>
                <div class="card-body">
                  <div v-if="this.$store.state.loader">
                      <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
                  </div>
                  <div v-else>
                    <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                        class="page-number"
                        v-model="currentPage"
                        type="number"
                        min="1"
                        :max="mySchedulesData.length > 0 ? Math.ceil(mySchedulesData.length/perPage) : 1"
                      ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="mySchedulesData.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                  <b-table
                    class="table table-custom"
                    :items="mySchedulesData"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    show-empty
                    :filter="filter"
                    sticky-header
                  >
                  <template v-slot:cell(start_time)="data">
                      {{ data.item.intime}}
                  </template>
                  <template v-slot:cell(off_time)="data">
                      {{ data.item.outime}}
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ data.item.archive ==0 ? 'Present Schedule' : 'Past Schedule'}}
                  </template>
                  <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                          <div class="d-flex justify-content-md-around">
                             <router-link class="button button1" v-if="$store.state.permittedActions.includes(442)" :to="{ name: 'edit-my-schedule', params: { id: data.item.id }}" title="edit"><i class="icon-pencil"></i></router-link>
                             <a class="button button1" v-if="$store.state.permittedActions.includes(443)"  title="delete" @click.prevent="deleteSchedule( data.item.id)"><i class="icon-bin"></i></a>
                          </div>
                      </div>
                  </template>
                  </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            currentPage: 1,
            perPage: 15,
            filter: null,
            sortIconNeutral: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconDesc: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconAsc: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortBy: null,
            sortDesc: false,
            fields: [
                {
                    key: 'datefrom',
                    label: 'From Date',
                    sortable: true
                },
                {
                    key: 'dateto',
                    label: 'To Date',
                    sortable: true
                },
                {
                    key: 'start_time',
                    label: 'Start_time',
                    tdAttr: { 'custom-label'  : "Start time:"}
                },
                {
                    key: 'off_time',
                    tdAttr: { 'custom-label'  : "Off time:"}
                },
                {
                    key: 'hours',
                    label: 'Total hours',
                    tdAttr: { 'custom-label'  : "Total hours:"}
                },
                {
                    key: 'restday',
                    tdAttr: { 'custom-label'  : "Restday:"}
                },
                {
                    key: 'datefrom',
                    label: 'From Date',
                    tdAttr: { 'custom-label'  : "From Date:"},
                    sortable: true
                },
                {
                    key: 'dateto',
                    label: 'To Date',
                    tdAttr: { 'custom-label'  : "To Date:"},
                    sortable: true
                },
                {
                    key: 'status',
                    tdAttr: { 'custom-label'  : "Status:"}
                },
                {
                  key: 'action',
                    tdAttr: { 'custom-label'  : "Action:"}
                }
            ]
        }
    },
    computed: {
      mySchedulesData(){
        return this.$store.state.mySchedulesData.s;
      }
    },
    created(){
      var token = localStorage.getItem('token');
      var role_id = localStorage.getItem('role_id');
      if(token == '' || token == null){
        window.location.href="/login";
      }
      this.$store.dispatch('switchLoader',true);
        this.$store.dispatch('loadMySchedules');
      
    },
    mounted() {
        
    },
    methods:{
      mySortCompare(a, b, key) {
          var firstDate;
          var secondDate;
          if(key == 'datefrom'){
            firstDate = new Date(a.datefrom);
            secondDate = new Date(b.datefrom);
          }
          else if(key == 'dateto'){
            firstDate = new Date(a.dateto);
            secondDate = new Date(b.dateto);
          }
          if(key == 'datefrom' || key == 'dateto'){
            firstDate = firstDate.getTime();
            secondDate = secondDate.getTime();
            return firstDate - secondDate;
          }
          else{
            return false;
          }
        },
        deleteSchedule(id){
        if(confirm("Are you sure, you want to delete this schedule ?")){
          axios.get('/schedules/delete/'+id)
            .then(({data}) => {
              if(data.status == 'success')
              this.flashMessage.success({
              message: 'Schedule deleted successfully',
              time: 3000
            });
            this.$store.dispatch('loadMySchedules');
            })
        }
      },
    }
}
</script>
